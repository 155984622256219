import { inject } from '@angular/core';
import { TokenService } from './token.service';
import { Router } from '@angular/router';

export const authAnonymousGuard = () => {
  const tokenService = inject(TokenService);
  const router = inject(Router);

  if (tokenService.isTokenValid()) {
    return router.navigate(['/']);
  }

  return true;
};
