<ng-container *ngrxLet="authFacade.user$ as user">
  <givve-frame
    [user]="user"
    [version]="version"
    [logoutText]="'Logout'"
    (logout)="onLogout()"
  >
    <givve-frame-logo
      data-cy="logo"
      routerLink="/"
      [width]="'213'"
      [height]="'42'"
      logo="../../../../assets/img/up-logo-with-wording.png"
    ></givve-frame-logo>
    <givve-frame-toolbar class="tw-z-50">
      @if (!routerHistoryService.currentUrl.includes('/search')) {
        <app-global-search-field class="tw-w-full tw-pr-6"></app-global-search-field>
      }
      <givve-toolbar-profile [user]="user">
        <button
          mat-menu-item
          (click)="onOldAdminClick()"
        >
          {{ 'common.old_admin' | translate }}
        </button>
      </givve-toolbar-profile>
    </givve-frame-toolbar>
    <givve-frame-nav
      *ngrxLet="{
        navItems: navItems$,
        isHelpDeskUser: isHelpDeskUser$,
      } as vm"
    >
      @if (vm.isHelpDeskUser) {
        <ng-container>
          <mat-list-item
            data-cy="frame-list-item-new-customer"
            (click)="dialog.open(customerCreateDialogComponent, clientCreateDialogConfig)"
            class="tw-cursor-pointer"
          >
            <fa-icon
              icon="plus"
              class="tw-mr-[22px] tw-w-[24px] tw-text-center"
              size="xl"
            />
            {{ 'frame.new_customer' | translate }}
          </mat-list-item>
          <mat-list-item
            data-cy="frame-list-item-new-multiplier"
            (click)="dialog.open(multiplierCreateDialogComponent, clientCreateDialogConfig)"
            class="tw-cursor-pointer"
          >
            <fa-icon
              icon="plus"
              class="tw-mr-[22px] tw-w-[24px] tw-text-center"
              size="xl"
            />
            {{ 'frame.new_multiplier' | translate }}
          </mat-list-item>
        </ng-container>
      }
      @for (item of vm.navItems; track item.path) {
        <ng-container>
          @if (!item['children']) {
            <givve-nav-item
              style="--mat-list-active-indicator-shape: 0px"
              [item]="item | asNavItem"
            >
              {{ item.title }}</givve-nav-item
            >
          } @else {
            <givve-nav-item-group
              [icon]="item.icon"
              [highlight]="routerHistoryService.currentUrl?.indexOf(item.path) !== -1"
            >
              {{ item.title }}
              <givve-nav-item
                *ngFor="let child of item['children']"
                style="--mat-list-active-indicator-shape: 0px"
                [item]="child"
              >
                {{ child.title }}</givve-nav-item
              >
            </givve-nav-item-group>
          }
        </ng-container>
      }
    </givve-frame-nav>
    <givve-frame-content data-cy="main-content">
      <app-content-wrapper>
        <router-outlet></router-outlet>
      </app-content-wrapper>
    </givve-frame-content>
  </givve-frame>
</ng-container>
