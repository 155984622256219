<mat-form-field data-cy="client-carrier-type">
  <mat-label>
    {{ 'common.carriertype' | translate }} {{ isRecarding() ? '(' + ('common.renew' | translate) + ')' : null }}
  </mat-label>
  <mat-select
    [formControl]="control()"
    required
    data-cy="client-carrier-type-select"
  >
    <mat-option
      *ngFor="
        let carrierType of isRecarding()
          ? (carrierTypes$ | async)?.carrierTypesRecarding
          : (carrierTypes$ | async)?.carrierTypes
      "
      [value]="carrierType.exceet_id"
    >
      {{ carrierType.description }}
    </mat-option>
  </mat-select>
  <button
    data-cy="client-carrier-type-suggestion"
    mat-stroked-button
    matSuffix
    (click)="onHintClick($event)"
  >
    {{ 'common.carrier_types.' + suggestion | translate }}
  </button>
  <mat-error *ngIf="control().errors?.required">
    {{ 'common.fill_out_field' | translate }}
  </mat-error>
</mat-form-field>
