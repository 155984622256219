<app-fieldset [title]="'common.beneficial-owner' | translate">
  <form [formGroup]="formGroup()">
    <ng-container formArrayName="liable_persons">
      @for (liablePersonForm of liablePersons().controls; track liablePersonForm; let i = $index) {
        <div
          data-cy="liable-persons-form-group"
          class="tw-mt-4 tw-grid tw-grid-cols-4 tw-items-center tw-gap-4"
          [formGroup]="liablePersonForm"
        >
          <mat-form-field data-cy="liable-persons-form-first-name">
            <mat-label> {{ 'common.first_name' | translate }} </mat-label>
            <input
              formControlName="first_name"
              matInput
              givveTrim
            />
            <mat-error *ngIf="liablePersonForm.controls.first_name.errors?.required">
              {{ 'common.fill_out_field' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field data-cy="liable-persons-form-last-name">
            <mat-label> {{ 'common.last_name' | translate }} </mat-label>
            <input
              formControlName="last_name"
              matInput
              givveTrim
            />
            <mat-error *ngIf="liablePersonForm.controls.last_name.errors?.required">
              {{ 'common.fill_out_field' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field data-cy="liable-persons-form-date-of-birth">
            <mat-label> {{ 'common.date_of_birth' | translate }} </mat-label>
            <input
              [matDatepicker]="picker"
              matInput
              formControlName="date_of_birth"
            />
            <mat-datepicker-toggle
              data-cy="datepicker-button"
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="liablePersonForm.controls.date_of_birth.errors?.required">
              {{ 'common.fill_out_field' | translate }}
            </mat-error>
            @if (liablePersonForm.controls.date_of_birth.hasError('matDatepickerParse')) {
              <mat-error>
                {{ 'common.errors.invalid_date' | translate }}
              </mat-error>
            }
          </mat-form-field>

          <mat-form-field data-cy="liable-persons-form-nationality">
            <mat-label> {{ 'common.nationality' | translate }} </mat-label>
            <mat-select
              data-cy="client-representative-add-dialog-nationality"
              formControlName="nationality"
              placeholder="{{ 'common.select_placeholder' | translate }}"
            >
              @for (country of countryService.countriesWithNumericCode; track country.code) {
                <mat-option [value]="country.code">
                  {{ country.name }}
                </mat-option>
              }
            </mat-select>
            <mat-error *ngIf="liablePersonForm.controls.nationality.errors?.required">
              {{ 'common.fill_out_field' | translate }}
            </mat-error>
          </mat-form-field>
          @if (i !== 0 || liablePersons().controls.length > 1) {
            <button
              mat-button
              data-cy="liable-persons-form-remove-button"
              (click)="removeLiablePerson(i)"
              class="tw-col-span-1 tw-flex-[0_0_65px]"
            >
              <svg-icon key="delete"></svg-icon>
            </button>
          }
        </div>
      }
    </ng-container>
  </form>

  <button
    class="icon-text-btn tw-mr-4 tw-content-start"
    mat-button
    data-cy="liable-persons-form-add-button"
    (click)="addLiablePerson()"
  >
    {{ 'common.add_more_contacts' | translate }}
    <svg-icon
      class="tw-ml-2"
      fontSize="18px"
      key="add"
    ></svg-icon>
  </button>
</app-fieldset>
