import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { REGISTER_NUMBER_PATTERN } from '@app/features/clients/clients.constants';
import { REGISTER_COURTS, REGISTER_NUMBER_COURTS_MAP, REGISTER_TYPES } from '@app/features/customer-create/constants';
import { TrimDirective } from '@givve/ui-kit/directives';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-register-number',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    TrimDirective,
  ],
  templateUrl: './register-number.component.html',
})
export class RegisterNumberComponent implements OnInit {
  registerTypeControl = input.required<FormControl>();
  registerNumberControl = input.required<FormControl>();
  registerCourtIdControl = input.required<FormControl>();
  registerNotAvailableControl = input.required<FormControl>();

  registerTypes = REGISTER_TYPES;
  registerCourts = REGISTER_COURTS;

  ngOnInit() {
    this.registerNumberControl().valueChanges.subscribe((registerNumber) => {
      const match = registerNumber?.match(REGISTER_NUMBER_PATTERN);
      if (!match || (match && !match[2])) {
        return;
      }
      this.registerCourtIdControl().patchValue(REGISTER_NUMBER_COURTS_MAP[match[2]]);
      this.registerCourtIdControl().updateValueAndValidity();
    });

    this.registerNotAvailableControl().valueChanges.subscribe((isNotAvailable) => {
      if (isNotAvailable) {
        this.registerTypeControl().disable();
        this.registerNumberControl().disable();
        this.registerCourtIdControl().disable();
      } else {
        this.registerTypeControl().enable();
        this.registerNumberControl().enable();
        this.registerCourtIdControl().enable();
      }
    });
  }
}
