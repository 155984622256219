<givve-fullscreen-layout>
  <div class="tw-min-w-[320px]">
    <div
      data-cy="not-found-note"
      class="tw-mb-4 tw-text-center tw-text-xl tw-font-bold"
    >
      {{ 'common.not_found' | translate }}
    </div>
    <div
      data-cy="not-found-text"
      class="tw-text-center"
      [innerHTML]="'not_found.component.text' | translate"
    ></div>
    <div class="tw-my-6 tw-flex tw-flex-col tw-justify-center">
      <div class="tw-mb-7 tw-flex tw-justify-center">
        <img
          data-cy="not-found-icon"
          class="tw-size-28"
          src="/assets/img/not-found.png"
        />
      </div>
      <div class="tw-flex tw-justify-center">
        <button
          data-cy="link-button"
          mat-raised-button
          color="primary"
          (click)="onBack()"
          *ngrxLet="isLoggedIn$ as isLoggedIn"
        >
          @if (isLoggedIn) {
            <span>{{ 'not_found.component.go_to_home' | translate }}</span>
          } @else {
            <span>{{ 'not_found.component.go_to_login' | translate }}</span>
          }
        </button>
      </div>
    </div>
  </div>
</givve-fullscreen-layout>
