import { Injectable } from '@angular/core';
import { CarrierType } from '@app/api/interfaces/carrier-type.interface';
import { MultiObjectHTTPService } from '@app/api/services/multi-object-http.service';
import { URITemplate } from '@app/api/services/uri-template';

@Injectable({ providedIn: 'root' })
export class CarrierTypesHttpService extends MultiObjectHTTPService<CarrierType> {
  memberUri = new URITemplate('');
  searchUri = new URITemplate('');
  collectionUri = new URITemplate('{+api_v1_base}/carrier_types');
}
