import type { User } from '@givve/ui-kit/models';
export enum UserRole {
  SUPERUSER = 'superuser',
  HELPDESK = 'helpdesk',
  TRANSACTION_ASSIGNMENT = 'transaction_assignment',
  EXTERNAL = 'external',
  COMPLIANCE_MANAGER = 'compliance_manager',
  COMPLIANCE_API = 'compliance_api',
  PAYMENT_MANAGER = 'payment_manager',
  THREE_DEE_SECURE = 'three_dee_secure',
  MERCHANT_MANAGER = 'merchant_manager',
}

export interface UserAdmin extends User {
  roles: UserRole[];
}
