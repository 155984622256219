<form [formGroup]="formGroup()">
  <div class="tw-grid tw-gap-4 md:tw-grid-cols-2">
    <mat-form-field>
      <mat-label> {{ 'card-order-form.new_card' | translate }} </mat-label>
      <input
        formControlName="default_card_price"
        matInput
        currencyMask
        required
      />
      <button
        mat-stroked-button
        matSuffix
        class="tw-ml-1"
        data-cy="card-order-form-default-card-price-suggestion"
        *ngFor="let suggestion of suggestions.default_card_price"
        (click)="onDefaultCardPriceSuggestionClick($event, suggestion)"
      >
        {{ suggestion | currency }}
      </button>
      <mat-error *ngIf="formGroup().controls.default_card_price.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> {{ 'common.renew' | translate }} </mat-label>
      <input
        formControlName="default_renew_card_price"
        matInput
        currencyMask
        required
      />
      <button
        mat-stroked-button
        matSuffix
        data-cy="card-order-form-default-renew-card-price-suggestion"
        *ngFor="let suggestion of suggestions.default_renew_card_price"
        class="tw-ml-1"
        (click)="onDefaultRenewCardPriceSuggestionClick($event, suggestion)"
      >
        {{ suggestion | currency }}
      </button>
      <mat-error *ngIf="formGroup().controls.default_renew_card_price.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <span
    data-cy="card-order-form-hint"
    *ngIf="multiplier?.default_card_order_invoice_recipient_type"
    class="tw-mt-1"
    [innerHTML]="
      multiplier['default_card_order_invoice_recipient_type'] === 'customer'
        ? ('card-order-form.hint' | translate)
        : ('card-order-form.hint-2' | translate)
    "
  >
  </span>
  <div class="tw-grid tw-gap-4 md:tw-grid-cols-2">
    <mat-form-field>
      <mat-label> {{ 'card-order-form.single_shipping' | translate }} </mat-label>
      <input
        formControlName="default_single_shipping_fee"
        matInput
        currencyMask
        required
      />
      <button
        mat-stroked-button
        matSuffix
        data-cy="card-order-form-default-single-shipping-fee-suggestion"
        (click)="onDefaultSingleShippingFeeSuggestionClick($event, suggestions.default_single_shipping_fee)"
      >
        {{ suggestions.default_single_shipping_fee | currency }}
      </button>
      <mat-error *ngIf="formGroup().controls.default_single_shipping_fee.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label> {{ 'card-order-form.bulk_shipping' | translate }} </mat-label>
      <input
        formControlName="default_bulk_shipping_fee"
        matInput
        currencyMask
        required
      />
      <button
        mat-stroked-button
        matSuffix
        data-cy="card-order-form-default-bulk-shipping-fee-suggestion"
        (click)="onDefaultBulkShippingFeeSuggestionClick($event, suggestions.default_bulk_shipping_fee)"
      >
        {{ suggestions.default_bulk_shipping_fee | currency }}
      </button>
      <mat-error *ngIf="formGroup().controls.default_bulk_shipping_fee.errors?.required">
        {{ 'common.fill_out_field' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
