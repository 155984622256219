import { CdkStepperModule } from '@angular/cdk/stepper';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Person } from '@app/api';
import { ADDRESS_STRING_PATTERN, REGISTER_NUMBER_PATTERN } from '@app/features/clients/clients.constants';
import { AddressFormComponent } from '@app/features/customer-create/components/address-form/address-form.component';
import { CardOrderFormComponent } from '@app/features/customer-create/components/card-order-form/card-order-form.component';
import { ClientCarrierTypeComponent } from '@app/features/customer-create/components/client-carrier-type/client-carrier-type.component';
import { ClientContactPersonsFormComponent } from '@app/features/customer-create/components/client-contact-persons-form/client-contact-persons-form.component';
import { ClientVatZoneComponent } from '@app/features/customer-create/components/client-vat-zone/client-vat-zone.component';
import { DefaultProductRefComponent } from '@app/features/customer-create/components/default-product-ref/default-product-ref.component';
import { GpsUsageGroupComponent } from '@app/features/customer-create/components/gps-usage-group/gps-usage-group.component';
import { LiablePersonsFormComponent } from '@app/features/customer-create/components/liable-persons-form/liable-persons-form.component';
import { LoadFeeFormComponent } from '@app/features/customer-create/components/load-fee-form/load-fee-form.component';
import { RegisterNumberComponent } from '@app/features/customer-create/components/register-number/register-number.component';
import { CustomerCreateStore } from '@app/features/customer-create/store/customer-create.store';
import {
  CopyEmailToContactPersonDirective,
  createZipCodeValidator,
  removeNullAndEmptyFromObject,
  valueToCents,
} from '@app/shared';
import { ClientAutocompleteFieldComponent } from '@app/shared/components/client-autocomplete-field/client-autocomplete-field.component';
import { ClientLegalFormComponent } from '@app/shared/components/client-legal-form/client-legal-form.component';
import { FieldsetComponent } from '@app/shared/components/fieldset/fieldset.component';
import { XInvoiceBuyerReferenceFieldComponent } from '@app/shared/components/x-invoice-buyer-reference-field/x-invoice-buyer-reference-field.component';
import { ContactPerson } from '@app/shared/models/contact-person';
import { ButtonSpinnerComponent, StepperComponent } from '@givve/ui-kit/components';
import { TrimDirective } from '@givve/ui-kit/directives';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

const languageCodes = ['de', 'en'];

@Component({
  selector: 'app-customer-create-dialog',
  templateUrl: './customer-create-dialog.component.html',
  styleUrls: ['./customer-create-dialog.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    NgFor,
    AsyncPipe,
    MatStepperModule,
    SvgIconComponent,
    TranslateModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    ButtonSpinnerComponent,
    ClientLegalFormComponent,
    RegisterNumberComponent,
    ClientVatZoneComponent,
    GpsUsageGroupComponent,
    DefaultProductRefComponent,
    ClientCarrierTypeComponent,
    LoadFeeFormComponent,
    CardOrderFormComponent,
    ClientContactPersonsFormComponent,
    LiablePersonsFormComponent,
    StepperComponent,
    CdkStepperModule,
    ClientAutocompleteFieldComponent,
    AddressFormComponent,
    FieldsetComponent,
    MatTooltipModule,
    CopyEmailToContactPersonDirective,
    TrimDirective,
    XInvoiceBuyerReferenceFieldComponent,
  ],
  providers: [CustomerCreateStore],
})
export class CustomerCreateDialogComponent {
  languageCodes = languageCodes;

  private customerCreateStore = inject(CustomerCreateStore);
  private fb = inject(FormBuilder);

  vm$ = this.customerCreateStore.vm$;

  addressFormGroup = this.fb.group(
    {
      company: [null, [Validators.required, Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(255)]],
      contact_person: [null, [Validators.pattern(ADDRESS_STRING_PATTERN)]],
      address_line_1: [
        null,
        [Validators.required, Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(50)],
      ],
      address_line_2: [null, [Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(50)]],
      zip_code: [null, [Validators.required]],
      city: [null, [Validators.required, Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(20)]],
      country_code: ['276', [Validators.required]],
    },
    {
      validators: [createZipCodeValidator()],
    }
  );

  deliveryAddressFormGroup = this.fb.group(
    {
      company: [null, [Validators.pattern(ADDRESS_STRING_PATTERN)]],
      contact_person: [null, [Validators.pattern(ADDRESS_STRING_PATTERN)]],
      address_line_1: [null, [Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(50)]],
      address_line_2: [null, [Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(50)]],
      zip_code: [null],
      city: [null, [Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(20)]],
      country_code: [null],
    },
    {
      validators: [createZipCodeValidator()],
    }
  );

  step1FormGroup = this.fb.group({
    addressFormGroup: this.addressFormGroup,
    deliveryAddressFormGroup: this.deliveryAddressFormGroup,
  });

  vatFormGroup = this.fb.group({
    vat_number: [null],
    x_invoice_buyer_reference: [null],
    vat_zone: [null, [Validators.required]],
  });

  multiplierFormGroup = this.fb.group({
    multiplier: [null],
  });

  productionsDataFormGroup = this.fb.group({
    gps_usage_group: [null, [Validators.required]],
    default_product_ref: [null, [Validators.required]],
    default_carrier_type: [null, [Validators.required]],
    default_renew_carrier_type: [null, [Validators.required]],
  });

  loadFeeFormGroup = this.fb.group({
    load_fee_percentage: [null, Validators.required],
    load_fee_min: [null, Validators.required],
    unload_fee: [null, Validators.required],
  });

  cardOrderFormGroup = this.fb.group({
    default_card_price: [null, [Validators.required]],
    default_renew_card_price: [null, [Validators.required]],
    default_single_shipping_fee: [null, [Validators.required]],
    default_bulk_shipping_fee: [null, [Validators.required]],
  });

  b2bSuiteFormGroup = this.fb.group({
    email: [null, [Validators.email]],
  });

  contactPersonsFormGroup = this.fb.group({
    contact_persons: this.fb.array([]),
  });

  liablePersonsFormGroup = this.fb.group({
    liable_persons: this.fb.array([]),
  });

  complianceFormGroup = this.fb.group({
    register_not_available: [null],
    legal_form: [null, [Validators.required]],
    register_type: [null, [Validators.required]],
    register_number: [null, [Validators.required, Validators.pattern(REGISTER_NUMBER_PATTERN)]],
    register_court_id: [null, [Validators.required]],
    liablePersonsFormGroup: this.liablePersonsFormGroup,
  });

  settingsFormGroup = this.fb.group({
    language_code: ['de', [Validators.required]],
  });

  accountsFormGroup = this.fb.group({
    multiplierFormGroup: this.multiplierFormGroup,
    b2bSuiteFormGroup: this.b2bSuiteFormGroup,
    contactPersonFormGroup: this.contactPersonsFormGroup,
    settingsFormGroup: this.settingsFormGroup,
  });

  feesFormGroup = this.fb.group({
    cardOrderFormGroup: this.cardOrderFormGroup,
    loadFeeFormGroup: this.loadFeeFormGroup,
  });

  onDoneClick() {
    const address = removeNullAndEmptyFromObject(this.addressFormGroup.value);
    const delivery_address = removeNullAndEmptyFromObject(this.deliveryAddressFormGroup.value);
    const multiplier = removeNullAndEmptyFromObject(this.multiplierFormGroup.value.multiplier);
    const loadFee = removeNullAndEmptyFromObject(this.loadFeeFormGroup.value);
    const cardOrder = removeNullAndEmptyFromObject(this.cardOrderFormGroup.value);
    const contactPersons = this.contactPersonsFormGroup?.value?.contact_persons!.filter(
      (c) => (c as ContactPerson).email
    );

    const liablePersons = this.liablePersonsFormGroup?.value?.liable_persons!.filter(
      (l) => (l as Person).last_name !== null
    );

    this.customerCreateStore.createCustomer({
      address,
      ...(delivery_address && Object.keys(delivery_address).length && { delivery_address }),
      ...removeNullAndEmptyFromObject(this.complianceFormGroup.value),
      ...removeNullAndEmptyFromObject(this.vatFormGroup.value),
      ...removeNullAndEmptyFromObject(this.productionsDataFormGroup.value),
      ...removeNullAndEmptyFromObject(this.b2bSuiteFormGroup.value),
      ...removeNullAndEmptyFromObject(this.settingsFormGroup.value),
      ...(!!multiplier?.id && { multiplier_id: multiplier.id }),
      ...(contactPersons.length > 0 && { contact_persons: contactPersons }),
      ...(liablePersons.length > 0 && { liable_persons: liablePersons }),
      load_fee_min: {
        cents: valueToCents(+loadFee?.load_fee_min!),
      },
      unload_fee: {
        cents: valueToCents(+loadFee?.unload_fee!),
      },
      load_fee_percentage: loadFee?.load_fee_percentage,
      default_card_price: {
        cents: valueToCents(+cardOrder?.default_card_price!),
      },
      default_renew_card_price: {
        cents: valueToCents(+cardOrder?.default_renew_card_price!),
      },
      default_single_shipping_fee: {
        cents: valueToCents(+cardOrder?.default_single_shipping_fee!),
      },
      default_bulk_shipping_fee: {
        cents: valueToCents(+cardOrder?.default_bulk_shipping_fee!),
      },
    });
  }
}
