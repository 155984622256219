// @ts-strict-ignore
import { BehaviorSubject } from 'rxjs';

export const cancelledRouteBeforeLogin: BehaviorSubject<string> = new BehaviorSubject(null);

export const LOGIN_PATH = '/login';

export const ACCESS_TOKEN_KEY = 'access_token';

export const REFRESH_TOKEN_KEY = 'refresh_token';

export const XSRF_TOKEN_KEY = 'XSRF-TOKEN';

export const REFRESH_TOKEN_PERIOD_IN_MS = 30000; // 30s

export const OTP_MIN_LENGTH = 6;
