import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { authAnonymousGuard } from './services/auth.anonymous.guard';

export default [
  {
    path: 'login',
    component: LoginComponent,
    title: 'login.component.login.login_title',
    canActivate: [authAnonymousGuard],
  },
] as Routes;
