<form
  [formGroup]="addressFormGroup()"
  class="tw-flex tw-gap-6"
>
  <div class="tw-flex tw-w-full tw-flex-col">
    <h4 class="tw-mb-5 tw-mt-0">{{ 'common.billing_address' | translate }}</h4>
    <app-client-address-form [addressFormGroup]="addressFormGroup()" />
    <mat-checkbox
      data-cy="address-form-same-address-checkbox"
      [checked]="isSameAddressesChecked"
      (change)="isSameAddressesChecked = !isSameAddressesChecked; deliveryAddressFormGroup().reset()"
      disableRipple
      >{{ 'address-form.delivery_address_billing_address' | translate }}</mat-checkbox
    >
  </div>
  <div class="tw-flex tw-w-full tw-flex-col">
    @if (!isSameAddressesChecked) {
      <h4 class="tw-mb-5 tw-mt-0">{{ 'common.delivery_address' | translate }}</h4>
      <app-client-address-form
        data-cy="address-form-delivery-address"
        [addressFormGroup]="deliveryAddressFormGroup()"
      />
    }
  </div>
</form>
