import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { OTP_MIN_LENGTH } from '@app/auth/auth.constants';
import { AuthFacade, LoginPayload } from '@app/auth/store/auth.facade';
import { FormFieldComponent } from '@app/shared/components/form-field/form-field.component';
import { ButtonSpinnerComponent, FullscreenLayoutComponent, InputOtpFieldComponent } from '@givve/ui-kit/components';
import { TrimDirective } from '@givve/ui-kit/directives';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    FullscreenLayoutComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ButtonSpinnerComponent,
    TranslateModule,
    InputOtpFieldComponent,
    LetDirective,
    FormFieldComponent,
    TrimDirective,
  ],
})
export class LoginComponent implements OnInit {
  public f = inject(AuthFacade);

  loading$ = this.f.loading$;

  error$ = this.f.error$;

  otpOpts = {
    length: OTP_MIN_LENGTH,
  };

  @ViewChild('otpInputRef') otpInputRef!: InputOtpFieldComponent;

  form = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    otp: new FormControl('', [Validators.required, Validators.minLength(OTP_MIN_LENGTH)]),
  });

  ngOnInit() {
    this.form.controls.otp.valueChanges.subscribe(() => {
      requestAnimationFrame(() => {
        if (this.form.valid) {
          this.f.login(this.form.value as LoginPayload);
        }
      });
    });

    // clear OTP field on failed login
    this.f.error$.pipe(untilDestroyed(this)).subscribe((error) => {
      if (error) {
        this.form.controls.otp.setValue('');
        this.otpInputRef.inputElements.forEach((el, index) => {
          if (index === 0) {
            el.nativeElement.focus();
          }
        });
      }
    });
  }

  onSubmit() {
    this.f.login(this.form.value as LoginPayload);
  }
}
