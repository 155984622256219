import { CommonModule, UpperCasePipe } from '@angular/common';
import { Component, OnInit, computed, inject, input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FieldsetComponent } from '@app/shared/components/fieldset/fieldset.component';
import { TrimDirective } from '@givve/ui-kit/directives';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule } from '@ngx-translate/core';

const emailNotificationValues = ['to', 'cc', 'bcc'];

@Component({
  selector: 'app-client-contact-persons-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    SvgIconComponent,
    MatButtonModule,
    MatSelectModule,
    UpperCasePipe,
    FieldsetComponent,
    TrimDirective,
  ],
  templateUrl: './client-contact-persons-form.component.html',
  styleUrls: ['./client-contact-persons-form.component.scss'],
})
export class ClientContactPersonsFormComponent implements OnInit {
  formGroup = input.required<FormGroup>();

  contactPersons = computed(() => {
    return this.formGroup().controls['contact_persons'] as FormArray<FormGroup>;
  });

  emailNotificationValues = emailNotificationValues;

  ngOnInit() {
    this.addContactPerson();
  }

  private fb = inject(FormBuilder);

  addContactPerson() {
    const contactPersonForm = this.fb.group({
      name: null,
      email: [null, Validators.email],
      email_notification: this.contactPersons().length === 0 ? 'to' : 'cc',
    });

    contactPersonForm.controls.name.valueChanges.subscribe((value) => {
      if (!!value) {
        contactPersonForm.controls.email.addValidators(Validators.required);
      } else {
        contactPersonForm.controls.email.removeValidators(Validators.required);
      }
      contactPersonForm.controls.email.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    });

    this.contactPersons().push(contactPersonForm);
  }

  removeContactPerson(index: number) {
    this.contactPersons().removeAt(index);
  }
}
