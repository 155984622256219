import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LEGAL_FORMS } from '@app/features/clients/clients.constants';
import { TranslateWithFallbackPipe } from '@app/shared/pipes/translate-with-fallback.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-client-legal-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateWithFallbackPipe,
    MatButtonModule,
    TranslateModule,
  ],
  templateUrl: './client-legal-form.component.html',
})
export class ClientLegalFormComponent implements OnInit {
  control = input.required<FormControl>();
  companyControl = input.required<FormControl>();
  legalForms = [null, ...LEGAL_FORMS];
  suggestion: string | null | undefined = null;

  ngOnInit() {
    this.companyControl().valueChanges.subscribe((value) => {
      const suggestions = this.legalForms.filter((legalForm) => value.toLowerCase().includes(legalForm?.toLowerCase()));
      this.suggestion = suggestions.length ? suggestions[0] : null;
    });
  }

  onHintClick(e: Event) {
    e.stopPropagation();
    this.control().patchValue(this.suggestion);
    this.control().updateValueAndValidity();
  }
}
