<form
  [formGroup]="formGroup()"
  class="tw-mt-4 tw-grid tw-gap-4 md:tw-grid-cols-3"
>
  <mat-form-field>
    <mat-label> {{ 'load-fee-form.load_fee_percentage' | translate }} </mat-label>
    <input
      formControlName="load_fee_percentage"
      mask="percent"
      decimalMarker=","
      suffix="%"
      matInput
      required
    />
    <button
      mat-stroked-button
      class="tw-ml-1"
      matSuffix
      data-cy="load-fee-form-load-fee-percentage-suggestion"
      (click)="onLoadFeePercentageSuggestionClick($event)"
    >
      {{ this.suggestions.load_fee_percentage | number }}%
    </button>
    <mat-error *ngIf="formGroup().controls.load_fee_percentage.errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label> {{ 'load-fee-form.load_fee_min' | translate }} </mat-label>
    <input
      formControlName="load_fee_min"
      currencyMask
      matInput
      required
    />
    <button
      mat-stroked-button
      matSuffix
      class="tw-ml-1"
      data-cy="load-fee-form-load-fee-min-suggestion"
      *ngFor="let suggestion of suggestions.load_fee_min"
      (click)="onLoadFeeMinSuggestionClick($event, suggestion)"
    >
      {{ suggestion | currency }}
    </button>
    <mat-error *ngIf="formGroup().controls.load_fee_min.errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label> {{ 'load-fee-form.unload_fee' | translate }} </mat-label>
    <input
      formControlName="unload_fee"
      currencyMask
      matInput
      required
    />
    <button
      mat-stroked-button
      matSuffix
      data-cy="load-fee-form-unload-fee-suggestion"
      (click)="onUnLoadFeeSuggestionClick($event)"
    >
      {{ this.suggestions.unload_fee | currency }}
    </button>
    <mat-error *ngIf="formGroup().controls.unload_fee.errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
  </mat-form-field>
</form>

<div
  *ngIf="multiplier?.hasOwnProperty('fee_invoice')"
  class="tw-mt-1"
  data-cy="load-fee-form-hint"
>
  <div
    *ngIf="!multiplier['fee_invoice']"
    [innerHTML]="'load-fee-form.hint' | translate"
  ></div>
  <div
    *ngIf="multiplier['fee_invoice']"
    [innerHTML]="'load-fee-form.hint-2' | translate"
  ></div>
  <div [innerHTML]="'load-fee-form.hint-3' | translate"></div>
</div>
