import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TrimDirective } from '@givve/ui-kit/directives';
import { Multiplier } from '@givve/ui-kit/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective } from 'ngx-mask';

@Component({
  selector: 'app-load-fee-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslateModule,
    NgxCurrencyDirective,
    MatButtonModule,
    NgxMaskDirective,
    TrimDirective,
  ],
  templateUrl: './load-fee-form.component.html',
})
export class LoadFeeFormComponent implements OnInit {
  formGroup = input.required<FormGroup>();
  multiplierControl = input.required<FormControl>();

  multiplier: Multiplier | null = null;

  defaultSuggestions = {
    load_fee_min: [2.5, 2.79],
    load_fee_percentage: 2.5,
    unload_fee: 10,
  };

  suggestions = { ...this.defaultSuggestions };

  ngOnInit() {
    this.multiplierControl().valueChanges.subscribe((multiplier) => {
      this.multiplier = multiplier;
      this.suggestions.load_fee_percentage = multiplier?.load_fee_percentage
        ? multiplier?.load_fee_percentage
        : this.defaultSuggestions.load_fee_percentage;
      this.suggestions.load_fee_min = multiplier?.load_fee_min
        ? [multiplier?.load_fee_min.cents / 100]
        : this.defaultSuggestions.load_fee_min;
      this.suggestions.unload_fee = multiplier?.unload_fee
        ? multiplier?.unload_fee.cents / 100
        : this.defaultSuggestions.unload_fee;
    });
  }

  onLoadFeePercentageSuggestionClick(e: Event) {
    e.stopPropagation();
    this.formGroup().get('load_fee_percentage')?.patchValue(this.suggestions.load_fee_percentage);
    this.formGroup().get('load_fee_percentage')?.updateValueAndValidity();
  }

  onLoadFeeMinSuggestionClick(e: Event, suggestion: number) {
    e.stopPropagation();
    this.formGroup().get('load_fee_min')?.patchValue(suggestion);
    this.formGroup().get('load_fee_min')?.updateValueAndValidity();
  }

  onUnLoadFeeSuggestionClick(e: Event) {
    e.stopPropagation();
    this.formGroup().get('unload_fee')?.patchValue(this.suggestions.unload_fee);
    this.formGroup().get('unload_fee')?.updateValueAndValidity();
  }
}
