import { Pipe, PipeTransform } from '@angular/core';
import { NavItem } from '@givve/ui-kit/features/frame/models/nav-item';

@Pipe({
  name: 'asNavItem',
  standalone: true,
})
export class AsNavItemPipe implements PipeTransform {
  transform(value: any): NavItem {
    return {
      path: value.path,
      icon: value.icon,
      title: value.title,
      queryParams: value.queryParams,
      routerLinkActiveOptions: value.routerLinkActiveOptions,
    } as NavItem;
  }
}
