// @ts-strict-ignore
import { Injectable, inject } from '@angular/core';
import { environment } from '@app/env';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorage } from '@givve/ui-kit/utils';
import moment, { MomentInput } from 'moment';
import { CookieService } from 'ngx-cookie-service';

import * as CONSTANTS from '@app/auth/auth.constants';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private cookieService = inject(CookieService);
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private accessToken: string;

  constructor() {
    // The access token might have been setup to the local storage by the current app or it might have been
    // already setup as a cookie value if the user has previously logged in to the legacy app.
    //
    this.accessToken =
      LocalStorage.getItem(CONSTANTS.ACCESS_TOKEN_KEY) || this.cookieService.get(CONSTANTS.ACCESS_TOKEN_KEY) || null;
  }

  setToken(accessToken: string): void {
    LocalStorage.setItem(CONSTANTS.ACCESS_TOKEN_KEY, accessToken);
    this.accessToken = accessToken;
  }

  getAccessToken(): string {
    return this.accessToken;
  }

  isValidJwt(token: string): boolean {
    try {
      this.jwtHelper.decodeToken(token);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * @returns Expiration time in Unix timestamp
   */
  getExpirationTime(): MomentInput | null {
    const token = this.getAccessToken();

    let expirationTime = null;
    try {
      expirationTime = moment(this.jwtHelper.getTokenExpirationDate(token));
    } catch (e) {
      expirationTime = null;
    }

    return expirationTime;
  }

  isTokenValid(current = moment()): boolean {
    const token = this.getAccessToken();

    if (!token) {
      return false;
    }

    try {
      return this.isValidJwt(token) && moment(this.jwtHelper.getTokenExpirationDate(token)).isAfter(current, 'minute');
    } catch (e) {
      return false;
    }
  }

  removeTokens() {
    LocalStorage.removeItem(CONSTANTS.ACCESS_TOKEN_KEY);
    this.cookieService.delete(CONSTANTS.ACCESS_TOKEN_KEY, '/', `.${environment.domain}`);
    this.cookieService.delete(CONSTANTS.XSRF_TOKEN_KEY, '/', `.${environment.domain}`);
    this.accessToken = null;
  }
}
