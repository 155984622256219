import { Injectable, inject } from '@angular/core';
import { UserRole } from '@app/api/interfaces/user.interface';
import { AuthComponentStore } from './auth.store';

export interface LoginPayload {
  username: string;
  password: string;
  otp: string;
}

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  private componentStore = inject(AuthComponentStore);

  isLoggedIn$ = this.componentStore.isLoggedIn$;
  error$ = this.componentStore.error$;
  loading$ = this.componentStore.loading$;
  user$ = this.componentStore.user$;
  userHasRole$ = (role: UserRole) => this.componentStore.select((state) => state.user?.roles.includes(role));

  init() {
    this.componentStore.initEffect();
  }

  login(payload: LoginPayload) {
    this.componentStore.loginEffect(payload);
  }

  logout() {
    this.componentStore.logoutEffect();
  }

  forceLogout(error?: string) {
    this.componentStore.forceLogoutEffect(error);
  }
}
