<givve-fullscreen-layout [showLogo]="false">
  <section>
    <div class="tw-mb-10 tw-mt-2 tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-space-y-2">
      <span class="tw-text-lg">{{ 'login.component.login.login_title' | translate }}</span>
      <span class="tw-text-md">{{ 'login.component.login.enter_info' | translate }}</span>
    </div>
    <form
      [formGroup]="form"
      novalidate
    >
      <mat-form-field
        data-cy="login-form-email-field"
        class="tw-my-1"
        hideRequiredMarker
      >
        <mat-label>
          {{ 'common.username' | translate }}
        </mat-label>
        <input
          formControlName="username"
          matInput
          required
          tabindex="1"
          givveTrim
          type="identifier"
          autofocus
        />
        <mat-hint>{{ 'common.required' | translate }}</mat-hint>
        @if (form.controls.username.errors?.required) {
          <mat-error> {{ 'common.fill_out_field' | translate }} </mat-error>
        }
      </mat-form-field>

      <mat-form-field
        data-cy="login-form-password-field"
        class="tw-my-1"
        hideRequiredMarker
      >
        <mat-label>
          {{ 'common.password' | translate }}
        </mat-label>
        <input
          autocomplete="off"
          formControlName="password"
          matInput
          required
          tabindex="2"
          givveTrim
          type="password"
        />
        <mat-hint>{{ 'common.required' | translate }}</mat-hint>
        @if (form.controls.password.errors?.required) {
          <mat-error> {{ 'common.fill_out_field' | translate }} </mat-error>
        }
      </mat-form-field>
      <mat-form-field
        floatLabel="always"
        hideRequiredMarker
        class="tw-mt-4"
      >
        <mat-label>{{ 'login.component.login.google_authenticator_code' | translate }}</mat-label>
        <!-- a matInput of text type is necessary for mat-form-field-->
        <input
          type="text"
          givveTrim
          hidden
          matInput
          formControlName="otp"
        />
        <givve-input-otp-field
          #otpInputRef
          data-cy="login-form-otp-field"
          [length]="otpOpts.length"
          formControlName="otp"
        />
        <mat-hint>{{ 'common.required' | translate }}</mat-hint>
        @if (form.controls.otp.errors?.required) {
          <mat-error> {{ 'common.fill_out_field' | translate }} </mat-error>
        }
      </mat-form-field>

      <ng-container
        *ngrxLet="{
          loading: loading$,
          error: error$,
        } as vm"
      >
        @if (vm.error) {
          <div
            class="tw-text-[var(--warn)]"
            data-cy="login-form-error-message"
          >
            {{ vm.error }}
          </div>
        }
        <div class="buttons">
          <div class="button-row">
            <button
              mat-flat-button
              (click)="onSubmit()"
              class="tw-h-[50px] tw-min-w-[160px] tw-rounded-[40px]"
              color="primary"
              [disabled]="form.invalid || form.pristine || vm.loading"
              data-cy="login-form-submit-button"
            >
              <givve-button-spinner [loading]="vm.loading">
                {{ 'login.component.login.register' | translate }}
              </givve-button-spinner>
            </button>
          </div>
        </div>
      </ng-container>
    </form>
  </section>
</givve-fullscreen-layout>
