import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public disableLoading() {
    this.isLoading$.next(false);
  }
}
