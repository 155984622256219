import { CommonModule } from '@angular/common';
import { Component, inject, input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CarrierTypesHttpService } from '@app/api/services/carrier-types-http.service';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { forkJoin, map } from 'rxjs';

const defaultCarrierTypes = {
  normal: '00000000000BONAYOU01',
  recarding: '00000000000BONAYOU03',
};

@Component({
  selector: 'app-client-carrier-type',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './client-carrier-type.component.html',
})
export class ClientCarrierTypeComponent implements OnInit {
  control = input.required<FormControl>();
  isRecarding = input.required<boolean>();
  multiplierControl = input<FormControl>();

  suggestion: string | null = null;

  private carrierTypesHttpService = inject(CarrierTypesHttpService);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);

  carrierTypes$ = forkJoin([
    this.carrierTypesHttpService.getAllObjects({ filter: { recarding: { $in: 'true' } } }),
    this.carrierTypesHttpService.getAllObjects({ filter: { recarding: { $in: 'false' } } }),
  ]).pipe(
    map(
      ([carrierTypesRecarding, carrierTypes]) => ({ carrierTypesRecarding, carrierTypes }),
      (error: any) => {
        this.notification.open({
          message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
        });
      }
    )
  );

  ngOnInit() {
    this.suggestion = this.isRecarding() ? defaultCarrierTypes.recarding : defaultCarrierTypes.normal;
    this.multiplierControl()?.valueChanges.subscribe((multiplier) => {
      this.suggestion = this.isRecarding()
        ? multiplier?.default_renew_carrier_type || defaultCarrierTypes.recarding
        : multiplier?.default_carrier_type || defaultCarrierTypes.normal;
      if (
        (this.isRecarding() && multiplier?.default_renew_carrier_type) ||
        (!this.isRecarding() && multiplier?.default_carrier_type)
      ) {
        this.control().patchValue(this.suggestion);
        this.control().updateValueAndValidity();
      } else {
        this.control().patchValue(null);
        this.control().updateValueAndValidity();
      }
    });
  }

  onHintClick(e: Event) {
    e.stopPropagation();
    this.control().patchValue(this.suggestion);
    this.control().updateValueAndValidity();
  }
}
