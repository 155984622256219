import { CdkStep } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ADDRESS_STRING_PATTERN } from '@app/features/clients/clients.constants';
import { ClientService } from '@app/features/clients/shared/services/client.service';
import { ClientCarrierTypeComponent } from '@app/features/customer-create/components/client-carrier-type/client-carrier-type.component';
import { ClientContactPersonsFormComponent } from '@app/features/customer-create/components/client-contact-persons-form/client-contact-persons-form.component';
import { ClientVatZoneComponent } from '@app/features/customer-create/components/client-vat-zone/client-vat-zone.component';
import {
  ClientAddressFormComponent,
  CopyEmailToContactPersonDirective,
  createZipCodeValidator,
  removeNullAndEmptyFromObject,
} from '@app/shared';
import { ClientLegalFormComponent } from '@app/shared/components/client-legal-form/client-legal-form.component';
import { FieldsetComponent } from '@app/shared/components/fieldset/fieldset.component';
import { XInvoiceBuyerReferenceFieldComponent } from '@app/shared/components/x-invoice-buyer-reference-field/x-invoice-buyer-reference-field.component';
import { ContactPerson } from '@app/shared/models/contact-person';
import { ButtonSpinnerComponent, StepperComponent } from '@givve/ui-kit/components';
import { TrimDirective } from '@givve/ui-kit/directives';
import { NotificationService } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multiplier-create-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    MatDialogModule,
    StepperComponent,
    ClientAddressFormComponent,
    CdkStep,
    MatButtonModule,
    ClientLegalFormComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    ClientVatZoneComponent,
    FormsModule,
    MatInputModule,
    ClientCarrierTypeComponent,
    FieldsetComponent,
    MatSelectModule,
    MatStepperModule,
    ClientContactPersonsFormComponent,
    MatTooltipModule,
    SvgIconComponent,
    ButtonSpinnerComponent,
    CommonModule,
    CopyEmailToContactPersonDirective,
    TrimDirective,
    XInvoiceBuyerReferenceFieldComponent,
  ],
  templateUrl: './multiplier-create-dialog.component.html',
  styleUrl: './multiplier-create-dialog.component.scss',
})
export class MultiplierCreateDialogComponent {
  protected languageCodes = ['de', 'en'];
  protected multiplierCreating = false;

  private fb = inject(FormBuilder);
  private clientService = inject(ClientService);
  private dialog = inject(MatDialog);
  private router = inject(Router);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);

  addressFormGroup = this.fb.group(
    {
      company: [null, [Validators.required, Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(255)]],
      contact_person: [null, [Validators.pattern(ADDRESS_STRING_PATTERN)]],
      address_line_1: [
        null,
        [Validators.required, Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(50)],
      ],
      address_line_2: [null, [Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(50)]],
      zip_code: [null, [Validators.required]],
      city: [null, [Validators.required, Validators.pattern(ADDRESS_STRING_PATTERN), Validators.maxLength(20)]],
      country_code: ['276', [Validators.required]],
    },
    {
      validators: [createZipCodeValidator()],
    }
  );

  vatFormGroup = this.fb.group({
    vat_number: [null],
    vat_zone: [null, [Validators.required]],
    legal_form: [null],
    x_invoice_buyer_reference: [null],
  });

  step1FormGroup = this.fb.group({
    addressFormGroup: this.addressFormGroup,
    vatFormGroup: this.vatFormGroup,
  });

  productionsDataFormGroup = this.fb.group({
    default_carrier_type: [null, [Validators.required]],
    default_renew_carrier_type: [null, [Validators.required]],
  });

  invoiceRecipientsFormGroup = this.fb.group({
    fee_invoice: [null, [Validators.required]],
    default_load_order_invoice_recipient_type: [null, [Validators.required]],
    default_card_order_invoice_recipient_type: [null, [Validators.required]],
  });

  step2FormGroup = this.fb.group({
    productionsDataFormGroup: this.productionsDataFormGroup,
    invoiceRecipientsFormGroup: this.invoiceRecipientsFormGroup,
  });

  contactPersonsFormGroup = this.fb.group({
    contact_persons: this.fb.array([]),
  });

  b2bSuiteSettingsFormGroup = this.fb.group({
    language_code: ['de', [Validators.required]],
    email: [null, [Validators.email]],
  });

  step3FormGroup = this.fb.group({
    b2bSuiteSettingsFormGroup: this.b2bSuiteSettingsFormGroup,
    contactPersonsFormGroup: this.contactPersonsFormGroup,
  });

  onDoneClick() {
    const contactPersons = this.contactPersonsFormGroup?.value?.contact_persons!.filter(
      (c) => (c as ContactPerson).email !== null
    );
    const address = removeNullAndEmptyFromObject(this.addressFormGroup.value);

    this.multiplierCreating = true;

    this.clientService
      .setHttpService('multiplier')
      .createClient({
        address,
        ...(contactPersons.length > 0 && { contact_persons: contactPersons }),
        ...removeNullAndEmptyFromObject(this.invoiceRecipientsFormGroup.value),
        ...removeNullAndEmptyFromObject(this.b2bSuiteSettingsFormGroup.value),
        ...removeNullAndEmptyFromObject(this.productionsDataFormGroup.value),
        ...removeNullAndEmptyFromObject(this.vatFormGroup.value),
      })
      .subscribe({
        next: (multiplier) => {
          this.multiplierCreating = false;
          this.dialog.closeAll();
          this.router.navigate(['/multipliers', multiplier.id]);
          this.notification.open({
            message: this.translate.instant('multiplier-create-dialog.multiplier_created_successfully'),
          });
        },
        error: (error) => {
          this.multiplierCreating = false;
          this.notification.open({
            message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
          });
        },
      });
  }
}
