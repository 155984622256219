<div class="tw-flex tw-items-center tw-gap-3">
  <mat-form-field data-cy="register-number-type">
    <mat-label> {{ 'register-number.register_type' | translate }} </mat-label>
    <mat-select
      [formControl]="registerTypeControl()"
      required
    >
      <mat-option
        *ngFor="let registerType of registerTypes"
        [value]="registerType"
      >
        {{ registerType }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="registerTypeControl().errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field data-cy="register-number-number">
    <mat-label> {{ 'register-number.number' | translate }} </mat-label>
    <input
      [formControl]="registerNumberControl()"
      matInput
      givveTrim
      required
    />
    <mat-error *ngIf="registerNumberControl().errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
    <mat-error *ngIf="registerNumberControl().errors?.pattern">
      {{ 'common.wrong_pattern_field' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field data-cy="register-number-court">
    <mat-label> {{ 'register-number.court' | translate }} </mat-label>
    <mat-select
      [formControl]="registerCourtIdControl()"
      required
    >
      <mat-option
        *ngFor="let registerCourt of registerCourts"
        [value]="registerCourt.code"
      >
        {{ registerCourt.value }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="registerCourtIdControl().errors?.required">
      {{ 'common.fill_out_field' | translate }}
    </mat-error>
  </mat-form-field>
  <input
    hidden
    matInput
  />
  <mat-checkbox
    data-cy="register-number-not-available-checkbox"
    disableRipple
    [formControl]="registerNotAvailableControl()"
    >{{ 'common.unavailable' | translate }}</mat-checkbox
  >
</div>
