import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import * as CONSTANTS from '@app/auth/auth.constants';
import { AuthService } from '@app/auth/services/auth.service';
import { TokenService } from '@app/auth/services/token.service';
import { LoadingService } from '@app/core';
import { User } from '@givve/ui-kit/models';
import { NotificationService, RouterHistoryService } from '@givve/ui-kit/services';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subject, interval } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoginPayload } from './auth.facade';

interface AuthState {
  loggedIn: boolean;
  error: string | null;
  loading: boolean;
  user: User | null;
}

export const DEFAULT_STATE: AuthState = {
  loggedIn: false,
  error: null,
  loading: false,
  user: null,
};

@Injectable()
export class AuthComponentStore extends ComponentStore<AuthState> {
  private unsubscribeRefreshTokenIntervals$ = new Subject<void>();

  isLoggedIn$ = this.select((state) => state.loggedIn);
  error$ = this.select((state) => state.error);
  loading$ = this.select((state) => state.loading);
  user$ = this.select((state) => state.user);

  private authService = inject(AuthService);
  private tokenService = inject(TokenService);
  private loadingService = inject(LoadingService);
  private notification = inject(NotificationService);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private routerHistoryService = inject(RouterHistoryService);

  constructor() {
    super(DEFAULT_STATE);
  }

  /**
   * Clear session tokens and navigate to URL
   * @param url - The URL to navigate to after login
   */
  private clearSessionTokensAndNavigate = (url = null) => {
    this.tokenService.removeTokens();
    this.router.navigateByUrl(url || CONSTANTS.LOGIN_PATH);
  };

  /**
   * @param message - The message to display in the notification
   */
  private clearAndSetNotification = (message: string) => {
    this.notification.clear();
    this.notification.open({ message });
    this.notification.reset();
  };

  /**
   * Update the URL to the last visited URL before login
   * @private
   * @memberof AuthStore
   */
  private getLatestUserVisitedUrl$ = CONSTANTS.cancelledRouteBeforeLogin.pipe(
    tap((cancelledUrl) => {
      // Clear any previous notification snackbars
      this.notification.clear();

      const { previousUrl } = this.routerHistoryService;

      const latestUserUrl = previousUrl || cancelledUrl;
      if (latestUserUrl) {
        this.router.navigateByUrl(latestUserUrl);
      } else {
        this.router.navigateByUrl('/');
      }
    })
  );

  /**
   * Refresh the token periodically
   *
   * @private
   * @memberof AuthStore
   */
  private refreshTokenPeriodically$ = interval(CONSTANTS.REFRESH_TOKEN_PERIOD_IN_MS).pipe(
    // Check after "REFRESH_TOKEN_PERIOD_IN_MS" ms if the access token is about to expire.
    // Because the access token acts as a refresh token, we do not want access token to expire
    // but rather request for a new one, few minutes/seconds before.
    //
    // Note that the "REFRESH_TOKEN_PERIOD_IN_MS" should be less than, the 2 minutes of extra time
    // we have added to determine when the token is about to expire.
    //
    // More info at https://app.asana.com/0/1202120806432471/1204030922281525
    tap(() => console.log('Refreshing token...')),
    filter(() => moment().add('2', 'minute').isAfter(this.tokenService.getExpirationTime(), 'minute')),
    switchMap(() =>
      this.authService.refresh(this.tokenService.getAccessToken()).pipe(
        tapResponse({
          next: (access_token) => {
            this.tokenService.setToken(access_token as unknown as string);
          },
          error: () => {
            this.clearAndSetNotification(this.translate.instant('common.session_expired'));
            this.clearSessionTokensAndNavigate();
          },
        })
      )
    ),
    takeUntil(this.unsubscribeRefreshTokenIntervals$)
  );

  readonly loginEffect = this.effect((loginPayload$: Observable<LoginPayload>) =>
    loginPayload$.pipe(
      tap(() => this.patchState({ loading: true, error: null })),
      switchMap((payload) =>
        this.authService.login(payload).pipe(
          tapResponse({
            next: (access_token: string) => {
              this.tokenService.setToken(access_token);
              this.initEffect();
            },
            error: () => {
              this.patchState({
                error: this.translate.instant('login.component.login.login_did_not_work'),
                loading: false,
              });
            },
            finalize: () => {
              this.patchState({ loading: false });
            },
          }),
          switchMap(() => this.getLatestUserVisitedUrl$)
        )
      )
    )
  );

  readonly initEffect = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      switchMap(() =>
        this.authService.getUser('me').pipe(
          tapResponse({
            next: (user: User) => {
              this.patchState({ user, loggedIn: true });
            },
            error: () => {
              this.forceLogoutEffect(this.translate.instant('common.session_expired'));
            },
            finalize: () => {
              this.loadingService.disableLoading();
            },
          }),
          switchMap(() => this.refreshTokenPeriodically$)
        )
      )
    )
  );

  readonly logoutEffect = this.effect((trigger$: Observable<void>) =>
    trigger$.pipe(
      tap(() => {
        this.unsubscribeRefreshTokenIntervals$.next();
        this.clearSessionTokensAndNavigate();
        this.patchState({ loggedIn: false, user: null });
      })
    )
  );

  readonly forceLogoutEffect = this.effect((params$: Observable<string | undefined>) =>
    params$.pipe(
      tap((errorMessage) => {
        this.logoutEffect();

        if (errorMessage) {
          this.clearAndSetNotification(errorMessage as string);
        }
      })
    )
  );
}
