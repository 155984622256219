import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { VALID_ZONES, ZONE_MAPPING } from '@app/features/customer-create/constants';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-client-vat-zone',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslateModule,
    MatButtonModule,
  ],
  templateUrl: './client-vat-zone.component.html',
})
export class ClientVatZoneComponent implements OnInit {
  control = input.required<FormControl>();
  countryCodeControl = input.required<FormControl>();

  validZones = VALID_ZONES;
  suggestion: string | null | undefined = null;

  ngOnInit() {
    this.suggestion = ZONE_MAPPING[this.countryCodeControl().value];
    this.countryCodeControl().valueChanges.subscribe((value: string) => {
      this.suggestion = ZONE_MAPPING[value];
    });
  }

  onHintClick(e: Event) {
    e.stopPropagation();
    this.control().patchValue(this.suggestion);
    this.control().updateValueAndValidity();
  }
}
