<mat-form-field data-cy="gps-usage-group">
  <mat-label> {{ 'common.scope' | translate }} </mat-label>
  <mat-select
    required
    [formControl]="control()"
    data-cy="gps-usage-group-suggestion-select"
  >
    <mat-option
      *ngFor="let option of gpsUsageGroupOptions"
      [value]="option"
    >
      {{ 'common.gps_usage_group.' + option | translate }}
    </mat-option>
  </mat-select>
  <button
    *ngIf="suggestion"
    mat-stroked-button
    matSuffix
    data-cy="gps-usage-group-suggestion"
    (click)="onHintClick($event)"
  >
    {{ 'common.gps_usage_group.' + suggestion | translate }}
  </button>
  <mat-error *ngIf="control().errors?.required">
    {{ 'common.fill_out_field' | translate }}
  </mat-error>
</mat-form-field>
